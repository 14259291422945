import { Modal } from "@mantine/core";
import React from "react";
export default function PremiumModal({ opened, onClose, children, title }) {
  const divStyle = {
    content: {
      padding: "1rem 0.5rem",
      maxHeight: '80vh'
    },
  };
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      withCloseButton={true}
      closeOnClickOutside={false}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 2,
      }}
      transitionProps={{
        transition: "fade",
        duration: 300,
        timingFunction: "linear",
      }}
      className="text-center text-base font-title font-semibold"
      size={"500px"}
      styles={divStyle}
      title={title}
    >
      {children}
    </Modal>
  );
}
