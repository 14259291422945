import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const MessagingCenter = createApi({
  reducerPath: "MessagingCenter",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["MessagingCenter"],

  endpoints: (builder) => ({
    getAllConversations: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/messages/chats`,
        params,
      }),
      providesTags: ["MessagingCenter"],
      keepUnusedDataFor: 0,
    }),
    getUnReadComments: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/messages/unread-chats`,
        params,
      }),
      providesTags: ["getUnReadComments"],
      keepUnusedDataFor: 0,
    }),
    getIndividualConversation: builder.query({
      query: (props) => ({
        method: "GET",
        url: `/messages/conversation?chat_id=${props?.chat_id ?props?.chat_id : '' }&page_size=${props?.page_size}&page=${props?.page}&${props?.recipient ? `user_id=${props?.recipient}` : ''}`,
      }),
      providesTags: ["getIndividualConversation"],
    }),
    messageStar: builder.mutation({
      query: (props) => {
        return {
          method: 'POST',
          url: `/messages/starred-status/${props?.id}`,
          body: { status: props?.status },

        };
      },
      invalidatesTags: ["messageStar"],
    }
    ),
    postNewMessage: builder.mutation({
      query: (props) => {
        console.log(props , 'triggerConversationtriggerConversation');
        return {
          method: 'POST',
          url: `/messages/send`,
          body: { content: props?.content, recipient: props?.recipient },

        };
      },
      invalidatesTags: ["postNewMessage"],
    }
    ),
  }),


});

export const {
  useGetAllConversationsQuery,
  useGetUnReadCommentsQuery,
  useLazyGetIndividualConversationQuery,
  useMessageStarMutation,
  usePostNewMessageMutation,
} = MessagingCenter;
