import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groupAuthorization: [
    { id: 12, name: "adham", isOwner: false },
    { id: 13, name: "aya", isOwner: false },
    { id: 14, name: "moamen", isOwner: true },
  ],
  groupAdmines: [
    { id: 92, name: "adel" },
    { id: 34, name: "foaud" },
    { id: 77, name: "ahmed" },
  ],
  groupOwner: { id: 14, name: "moamen", isOwner: true },
  isAuthoried: false,
};

const communitySlice = createSlice({
  name: "communitySlice",
  initialState,
  reducers: {
    setGroupOwner: (state, action) => {
      state.groupOwner = action.payload;
    },
    setAdmins: (state, action) => {
      state.groupAdmines = action.payload;
    },
    setIsAuthoried: (state, action) => {
      const result = state.groupAuthorization?.some(
        (ele) => ele?.id === action.payload
      );
      state.isAuthoried = result;
    },
  },
});

export const { groupOwner, setAdmins, isAuthoried, setIsAuthoried } =
  communitySlice.actions;
export const communityReducer = communitySlice.reducer;
